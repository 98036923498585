import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';

Vue.config.productionTip = false;

// Установка базового URL для axios в зависимости от окружения
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:3000'; // URL бэкенда для разработки
} else {
  axios.defaults.baseURL = '/api'; // URL бэкенда для продакшн
}

new Vue({
  render: h => h(App),
}).$mount('#app');

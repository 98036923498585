<template>
  <div id="app">
    <h1>PostgreSQL Database Copy</h1>
    <form @submit.prevent="copyDatabase">
      <h2>Source Database</h2>
      <input v-model="sourceHost" placeholder="Source Host" required />
      <input v-model="sourcePort" placeholder="Source Port" required />
      <input v-model="sourceUser" placeholder="Source User" required />
      <input v-model="sourcePassword" type="password" placeholder="Source Password" required />
      <input v-model="sourceDb" placeholder="Source Database" required />

      <h2>Target Database</h2>
      <input v-model="targetHost" placeholder="Target Host" required />
      <input v-model="targetPort" placeholder="Target Port" required />
      <input v-model="targetUser" placeholder="Target User" required />
      <input v-model="targetPassword" type="password" placeholder="Target Password" required />
      <input v-model="targetDb" placeholder="Target Database" required />

      <h2>Options</h2>
      <label>
        <input type="checkbox" v-model="copySchema" />
        Copy Schema
      </label>
      <label>
        <input type="checkbox" v-model="copyIndexes" />
        Copy Indexes
      </label>
      <label>
        <input type="checkbox" v-model="copyKeys" />
        Copy Keys
      </label>
      <label>
        <input type="checkbox" v-model="copySequences" />
        Copy Sequences
      </label>
      <label>
        <input type="checkbox" v-model="copyBlobs" />
        Copy Blobs
      </label>
      <label>
        <input type="checkbox" v-model="dropObjects" />
        Drop Target Object if Exists
      </label>

      <button type="submit">Copy Database</button>
    </form>
    <div v-if="status">{{ status }}</div>
    <div v-if="logOutput">
      <h2>Log Output:</h2>
      <pre>{{ logOutput }}</pre>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sourceHost: '',
      sourcePort: '',
      sourceUser: '',
      sourcePassword: '',
      sourceDb: '',
      targetHost: '',
      targetPort: '',
      targetUser: '',
      targetPassword: '',
      targetDb: '',
      copySchema: false,
      copyIndexes: false,
      copyKeys: false,
      copySequences: false,
      copyBlobs: false,
      dropObjects: false,
      status: '',
      logOutput: '',
      ws: null,
    };
  },
  methods: {
    async copyDatabase() {
      const payload = {
        sourceHost: this.sourceHost,
        sourcePort: this.sourcePort,
        sourceUser: this.sourceUser,
        sourcePassword: this.sourcePassword,
        sourceDb: this.sourceDb,
        targetHost: this.targetHost,
        targetPort: this.targetPort,
        targetUser: this.targetUser,
        targetPassword: this.targetPassword,
        targetDb: this.targetDb,
        copySchema: this.copySchema,
        copyIndexes: this.copyIndexes,
        copyKeys: this.copyKeys,
        copySequences: this.copySequences,
        copyBlobs: this.copyBlobs,
        dropObjects: this.dropObjects,
      };

      try {
        const response = await axios.post('/api/copydb', payload); // Use '/api/copydb' to ensure it goes through the proxy
        this.status = response.data.message;
        this.connectWebSocket(); // Connect WebSocket after initiating the copy
      } catch (error) {
        this.status = error.response ? error.response.data.error : 'Unknown error occurred';
      }
    },
    connectWebSocket() {
      if (this.ws) {
        this.ws.close(); // Close previous WebSocket connection if it exists
      }

      this.ws = new WebSocket('ws://' + window.location.host + '/ws/');

      this.ws.onopen = () => {
        console.log('WebSocket connection opened');
      };

      this.ws.onmessage = (event) => {
        this.logOutput += event.data + '\n';
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
      };
    }
  },
  mounted() {
    this.connectWebSocket(); // Automatically connect WebSocket on mount
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close(); // Clean up WebSocket connection on component destroy
    }
  }
};
</script>

<style>
#app {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
input {
  display: block;
  margin-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
}
button {
  margin-top: 10px;
}
pre {
  white-space: pre-wrap; /* Wraps long lines */
}
</style>
